import { TranslateService } from '@ngx-translate/core';
import { openVoidDialog, smallDialogSettings } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateNoteDialogOptions } from './create-note-dialog-options.type';
import { CreateNoteDialogComponent } from './create-note-dialog.component';

export async function openCreateNoteDialog(
    dialogService: DialogService,
    translate: TranslateService,
    options: CreateNoteDialogOptions,
): Promise<void> {
    return await openVoidDialog(dialogService, CreateNoteDialogComponent, {
        ...smallDialogSettings,
        header: translate.instant('notes.createHeader'),
        data: options,
        focusOnShow: false,
    });
}
