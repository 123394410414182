import { inject, Injectable, Provider, Signal, signal } from '@angular/core';
import { UserService } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { NoteApiService, NoteDto, openConfirmDialog, QuickNoteDto } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { openCreateNoteDialog } from './create-note-dialog';
import { CreateNote } from './create-note.type';
import { openEditNoteDialog } from './edit-note-dialog';
import { EditNote } from './edit-note.type';

export function provideNotesState(): Provider[] {
    return [NotesStateModel, { provide: NotesState, useClass: NotesStateModel }];
}

@Injectable()
export abstract class NotesState {
    public abstract reference: Signal<{ refId: string; refName: string; contractId: string } | null>;
    public abstract isLoading: Signal<boolean>;
    public abstract quickNotes: Signal<QuickNoteDto[]>;
    public abstract notes: Signal<NoteDto[]>;

    public abstract init(options: {
        refId: string;
        refName: string;
        contractId: string;
        loadExisting?: false;
    }): Promise<void>;
    public abstract isRemoving(note: NoteDto): boolean;
    public abstract create(): Promise<void>;
    public abstract isEditable(note: NoteDto): boolean;
    public abstract isRemovable(note: NoteDto): boolean;
    public abstract edit(note: NoteDto): Promise<void>;
    public abstract remove(note: NoteDto): Promise<void>;
}

@Injectable()
class NotesStateModel extends NotesState {
    private readonly dialogService = inject(DialogService);
    private readonly translate = inject(TranslateService);
    private readonly noteApiService = inject(NoteApiService);
    private readonly userService = inject(UserService);

    public override reference = signal<{ refId: string; refName: string; contractId: string } | null>(null);
    public override isLoading = signal<boolean>(true);
    public override quickNotes = signal<QuickNoteDto[]>([]);
    public override notes = signal<NoteDto[]>([]);

    protected removingIds = signal<string[]>([]);

    public override async init(options: { refId: string; refName: string; contractId: string; loadExisting?: false }) {
        const refId = options.refId;
        const refName = options.refName;
        const contractId = options.contractId;
        const loadExisting = options.loadExisting ?? true;

        this.reference.set({ refId, refName, contractId });

        try {
            this.isLoading.set(true);

            const [quickNotes, notes] = await Promise.all([
                this.noteApiService.getQuickNotes(),
                loadExisting ? this.noteApiService.getNotes(refId, refName) : Promise.resolve([]),
            ]);
            this.quickNotes.set(quickNotes);
            this.notes.set(notes);
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading.set(false);
        }
    }

    public override isRemoving(n: NoteDto): boolean {
        return this.removingIds().includes(n.id);
    }

    public override async create() {
        const reference = this.reference();
        if (reference === null) {
            return;
        }

        await openCreateNoteDialog(this.dialogService, this.translate, {
            quickNotes: this.quickNotes(),
            onCreate: async (options: CreateNote) => {
                await this.noteApiService.create({
                    refId: reference.refId,
                    refName: reference.refName,
                    contractId: reference.contractId,
                    subject: options.subject ?? '',
                    body: options.body ?? '',
                });

                await this.init({
                    refId: reference.refId,
                    refName: reference.refName,
                    contractId: reference.contractId,
                });
            },
        });
    }

    public override isEditable(note: NoteDto): boolean {
        return this.userService.userPrincipalId() === note.createdBy && note.createdOn.diffNow('months').months > -1;
    }

    public override async edit(note: NoteDto): Promise<void> {
        const reference = this.reference();
        if (reference === null || !this.isEditable(note)) {
            return;
        }

        await openEditNoteDialog(this.dialogService, this.translate, {
            quickNotes: this.quickNotes(),
            note: note,
            onEdit: async (options: EditNote) => {
                await this.noteApiService.update({
                    id: note.id,
                    subject: options.subject ?? '',
                    body: options.body ?? '',
                });

                await this.init({
                    refId: reference.refId,
                    refName: reference.refName,
                    contractId: reference.contractId,
                });
            },
        });
    }

    public override isRemovable(note: NoteDto): boolean {
        return this.isEditable(note);
    }

    public override async remove(note: NoteDto): Promise<void> {
        const reference = this.reference();
        if (reference === null || !this.isRemovable(note)) {
            return;
        }

        const accept = await openConfirmDialog(this.dialogService, {
            header: this.translate.instant('notes.confirmation.header'),
            message: this.translate.instant('notes.confirmation.message'),
            acceptLabel: this.translate.instant('actions.delete'),
            acceptSeverity: 'danger',
        });
        if (!accept) {
            return;
        }

        try {
            this.removingIds.update((ids) => [...ids, note.id]);
            await this.noteApiService.remove(note.id);
            this.notes.update((notes) => notes.filter((n) => n !== note));
        } catch (error) {
            console.error(error);
        } finally {
            this.removingIds.update((ids) => ids.filter((id) => id !== note.id));
        }
    }
}
