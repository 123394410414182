<div class="flex flex-column gap-3">
    <nuis-file-selector mode="multiple" (filesSelected)="filesSelected($event)" />

    @if (uploading().length > 0) {
        <div class="flex flex-column gap-2">
            @for (upload of uploading(); track $index) {
                <nuis-file-upload-progress [upload]="upload" />
            }
        </div>
    }

    <div class="flex flex-column gap-2">
        @if (isLoading()) {
            <div class="flex justify-content-center">
                <nuis-loading-indicator />
            </div>
        } @else {
            <nuis-sorter
                [sortField]="sortField()"
                [sortOrder]="sortOrder()"
                [sortings]="sortings()"
                (stateChange)="sortChanged($event)" />

            @for (document of sortedDocuments(); track $index) {
                <nuis-file-card [name]="document.fullName" [size]="document.size">
                    <ng-template #metdata>
                        <nuis-display-date-time [label]="'creationTime' | translate" [value]="document.creationTime" />

                        <nuis-display-text class="flex-1" [label]="'creator' | translate" [value]="document.creator" />
                    </ng-template>

                    <ng-template #actions>
                        <div class="flex gap-2">
                            <p-button
                                [text]="true"
                                icon="pi pi-download"
                                severity="secondary"
                                [disabled]="isRemoving()(document)"
                                [loading]="isDownloading()(document)"
                                [pTooltip]="'actions.download' | translate"
                                tooltipPosition="left"
                                (onClick)="handleDownload(document)" />

                            <p-button
                                [text]="true"
                                icon="pi pi-pencil"
                                [disabled]="isRemoving()(document)"
                                [pTooltip]="'actions.rename' | translate"
                                tooltipPosition="left"
                                (onClick)="handleRename(document)" />

                            <p-button
                                [text]="true"
                                icon="pi pi-trash"
                                severity="danger"
                                [loading]="isRemoving()(document)"
                                [pTooltip]="'actions.delete' | translate"
                                tooltipPosition="left"
                                (onClick)="handleRemove(document)" />
                        </div>
                    </ng-template>
                </nuis-file-card>
            }

            @if (sortedDocuments().length === 0 && uploading().length === 0) {
                <nuis-card>
                    <div class="text-center text-500">
                        {{ 'documents.empty' | translate }}
                    </div>
                </nuis-card>
            }
        }
    </div>
</div>
