import { Route } from '@angular/router';

export const coreRoutes: Route = {
    loadComponent: () => import('./core.component'),
    children: [
        {
            path: 'notifications',
            loadComponent: () => import('./pages/notifications/notifications.component'),
            title: 'notifications',
        },
        {
            path: 'notifications/:notificationId',
            loadComponent: () => import('./pages/notification-details/notification-details.component'),
            title: 'notificationDetails',
        },
    ],
};
