import { TranslateService } from '@ngx-translate/core';
import { openVoidDialog, smallDialogSettings } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { DocumentRenameDialogOptions } from './document-rename-dialog-options.type';
import { DocumentRenameDialogComponent } from './document-rename-dialog.component';

export async function openDocumentRenameDialog(
    dialogService: DialogService,
    translate: TranslateService,
    options: DocumentRenameDialogOptions,
): Promise<void> {
    return await openVoidDialog(dialogService, DocumentRenameDialogComponent, {
        ...smallDialogSettings,
        header: translate.instant('documents.rename.header'),
        data: options,
    });
}
