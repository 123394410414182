import { Component, computed, inject, input, output, signal } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
    CardComponent,
    DisplayDateTimeComponent,
    DisplayTextComponent,
    DocumentDto,
    FileCardComponent,
    FileSelectorComponent,
    FileUpload,
    FileUploadProgressComponent,
    LoadingIndicatorComponent,
    Paths,
    sortByDate,
    sortByText,
    SorterComponent,
    Sorting,
    SortState,
} from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'nuis-document-management',
    standalone: true,
    imports: [
        TranslatePipe,
        ButtonModule,
        TooltipModule,
        LoadingIndicatorComponent,
        CardComponent,
        FileSelectorComponent,
        FileUploadProgressComponent,
        FileCardComponent,
        SorterComponent,
        DisplayTextComponent,
        DisplayDateTimeComponent,
    ],
    templateUrl: './document-management.component.html',
})
export class DocumentManagementComponent {
    private readonly translate = inject(TranslateService);

    public isLoading = input.required<boolean>();
    public documents = input.required<DocumentDto[]>();
    public uploading = input.required<FileUpload<DocumentDto>[]>();
    public isDownloading = input.required<(d: DocumentDto) => boolean>();
    public isRemoving = input.required<(d: DocumentDto) => boolean>();
    public upload = output<File[]>();
    public download = output<DocumentDto>();
    public rename = output<DocumentDto>();
    public remove = output<DocumentDto>();

    protected sortedDocuments = computed<DocumentDto[]>(() => {
        const documents = this.documents();
        const sortOrder = this.sortOrder();
        const sortField = this.sortField();

        switch (sortField) {
            case 'fullName':
                return documents.sort((a, b) => sortByText(a.fullName, b.fullName, sortOrder));
            case 'creationTime':
                return documents.sort((a, b) => sortByDate(a.creationTime, b.creationTime, sortOrder));
            default:
                return documents;
        }
    });
    protected sortField = signal<Paths<DocumentDto>>('creationTime');
    protected sortOrder = signal<number>(-1);
    protected sortings = signal<Sorting<DocumentDto>[]>([
        { field: 'fullName', label: this.translate.instant('fileName') },
        { field: 'creationTime', label: this.translate.instant('creationTime') },
    ]);

    protected async sortChanged(state: SortState<DocumentDto>) {
        this.sortField.set(state.sortField);
        this.sortOrder.set(state.sortOrder);
    }

    protected filesSelected(files: File[]) {
        this.upload.emit(files);
    }

    protected handleDownload(document: DocumentDto) {
        this.download.emit(document);
    }

    protected handleRename(document: DocumentDto) {
        this.rename.emit(document);
    }

    protected handleRemove(document: DocumentDto) {
        this.remove.emit(document);
    }
}
