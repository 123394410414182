<div class="flex flex-column gap-4 p-3 surface-ground overflow-auto">
    <nuis-input-text
        [label]="'documents.rename.fileName' | translate"
        [control]="fileNameControl"
        [postfix]="extension()" />

    @if (!fileNameControl.pending && fileNameControl.value !== newFileName()) {
        <div class="flex flex-column gap-2">
            <div>
                {{ 'documents.rename.fileNameWillBeReplaced' | translate }}
                <span class="font-bold">{{ newFullname() }}</span>
            </div>
            <div class="text-500">{{ 'documents.rename.fileNameWillBeReplacedHint' | translate }}</div>
        </div>
    }
</div>

<div class="flex gap-3 justify-content-end p-3">
    <p-button
        [label]="'actions.cancel' | translate"
        [text]="true"
        severity="secondary"
        [disabled]="isSaving()"
        (onClick)="cancel()" />
    <p-button
        [label]="'actions.rename' | translate"
        [loading]="isSaving()"
        [disabled]="fileNameControl.pending"
        (onClick)="save()" />
</div>
