import { TranslateService } from '@ngx-translate/core';
import { openVoidDialog, smallDialogSettings } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { EditNoteDialogOptions } from './edit-note-dialog-options.type';
import { EditNoteDialogComponent } from './edit-note-dialog.component';

export async function openEditNoteDialog(
    dialogService: DialogService,
    translate: TranslateService,
    options: EditNoteDialogOptions,
): Promise<void> {
    return await openVoidDialog(dialogService, EditNoteDialogComponent, {
        ...smallDialogSettings,
        header: translate.instant('notes.editHeader'),
        data: options,
        focusOnShow: false,
    });
}
