<div class="flex flex-column gap-3">
    @if (quickNotes().length > 0) {
        <div class="flex flex-wrap gap-3 mb-3">
            @for (note of quickNotes(); track $index) {
                <p-chip
                    class="cursor-pointer"
                    [styleClass]="chipStyle(note)"
                    [label]="note.label | translate"
                    (click)="useQuickNote(note)" />
            }
        </div>
    }

    <nuis-input-text [label]="'notes.title' | translate" [control]="form().controls.subject" />

    <nuis-input-textarea [label]="'notes.body' | translate" [control]="form().controls.body" />

    @if (showErrors(form())) {
        <nuis-form-error [label]="null" [control]="form()" />
    }
</div>
