<div class="flex flex-column gap-4 p-3 surface-ground overflow-auto">
    <nuis-note-form [form]="form" [quickNotes]="quickNotes()" />
</div>

<div class="flex gap-3 justify-content-end p-3">
    <p-button
        [label]="'actions.cancel' | translate"
        [text]="true"
        severity="secondary"
        [disabled]="isCreating()"
        (onClick)="cancel()" />

    <p-button [label]="'actions.create' | translate" [loading]="isCreating()" (onClick)="create()" />
</div>
