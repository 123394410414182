import { Component, computed, input, output } from '@angular/core';
import { CreatorPipe } from '@luis/common/shared';
import { TranslatePipe } from '@ngx-translate/core';
import {
    CardComponent,
    LoadingIndicatorComponent,
    LuxonDateTimePipe,
    NoteDto,
    ProfilePictureComponent,
    sortByDate,
} from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'nuis-note-management',
    standalone: true,
    imports: [
        TranslatePipe,
        ButtonModule,
        TooltipModule,
        CreatorPipe,
        LoadingIndicatorComponent,
        CardComponent,
        ProfilePictureComponent,
        LuxonDateTimePipe,
    ],
    templateUrl: './note-management.component.html',
})
export class NoteManagementComponent {
    public isLoading = input.required<boolean>();
    public notes = input.required<NoteDto[]>();
    public isRemoving = input.required<(n: NoteDto) => boolean>();
    public isEditable = input.required<(n: NoteDto) => boolean>();
    public isRemoveable = input.required<(n: NoteDto) => boolean>();
    public create = output<void>();
    public edit = output<NoteDto>();
    public remove = output<NoteDto>();

    protected sortedNotes = computed<NoteDto[]>(() => {
        const notes = this.notes();
        return notes.sort((a, b) => sortByDate(a.createdOn, b.createdOn, -1));
    });

    protected handleCreate() {
        this.create.emit();
    }

    protected handleEdit(note: NoteDto) {
        this.edit.emit(note);
    }

    protected handleRemove(note: NoteDto) {
        this.remove.emit(note);
    }
}
