import { Component, inject, signal } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { injectDialogOptions, QuickNoteDto } from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NoteFormComponent } from '../note-form/note-form.component';
import { initNoteForm } from '../note-form/note-form.type';
import { EditNoteDialogOptions } from './edit-note-dialog-options.type';

@Component({
    selector: 'nuis-edit-note-dialog',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, DialogModule, NoteFormComponent],
    templateUrl: './edit-note-dialog.component.html',
})
export class EditNoteDialogComponent {
    private readonly options = injectDialogOptions<EditNoteDialogOptions>();
    private readonly dialogRef = inject(DynamicDialogRef);
    private readonly fb = inject(NonNullableFormBuilder);

    protected quickNotes = signal<QuickNoteDto[]>(this.options.quickNotes ?? []);
    protected form = initNoteForm(this.fb, this.options.note);
    protected isEditing = signal<boolean>(false);

    protected cancel() {
        this.dialogRef.close();
    }

    protected async edit() {
        this.form.markAsTouched();
        if (this.form.invalid) {
            return;
        }

        try {
            this.isEditing.set(true);
            const values = this.form.getRawValue();
            await this.options.onEdit(values);
            this.dialogRef.close();
        } catch (error) {
            console.error(error);
        } finally {
            this.isEditing.set(false);
        }
    }
}
